/*  */@import url('https://fonts.googleapis.com/css2?family=Arsenal&family=Fjalla+One&family=Fredoka+One&family=Fuzzy+Bubbles&family=Genos:wght@500&family=Krona+One&family=Merriweather+Sans:wght@300&family=Montserrat:wght@100&family=Press+Start+2P&family=Roboto:wght@300&family=Tajawal:wght@200&family=Titillium+Web:wght@300&display=swap');

.App {
  text-align: center;
  width: 100%;
  height: 100%;
}

* {
  margin: 0;
}

::-webkit-scrollbar {
  width: 0px;
}

body {
  font-family: 'Ramabhadra', sans-serif;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}