.welcome-container {
  width: 100%;
  height: 110vh;
  background-image: url(/public/images/maroTruck.JPG);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.welcome-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  color: aliceblue;
  background-color: rgba(32, 35, 37, 0.616);
  width: 45%;

}

h1 {
  font-size: 60px;
  /* color: aliceblue; */
  margin-top: 10px;
  margin-bottom: 0;
  letter-spacing: 3px;
}

h2 {
  font-size: 28px;
  margin-top: -20px;
}

@media screen and (max-width: 700px) {
  .welcome-container{
    height: 900px;
    background-image: url(https://static.displate.com/857x1200/displate/2019-09-11/d61efd6da99e0c56c3f4803b9eaed358_ceb9195be5f80754e044e56eca5303b8.jpg);
  }



  .welcome-content-container{
    /* background-color: transparent; */
    margin-top: 0px;
    width: 80%;
    margin-bottom: 20px;
    /* height: 100vh; */
  }

  h1 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 40px;
    line-height: 40px;
  }

  h2 {
    font-size: 20px;
    margin-top: 0;
    line-height: 25px;
  }
}