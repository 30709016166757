.about-container {
  height: 100%;
  background-color: rgba(32, 35, 37, 0.041);
  padding-bottom: 70px;
  padding-top: 70px;
}

.about-content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.about-title {
  font-size: 40px;
  margin-bottom: 50px;
  text-shadow: 2px 2px lightgray;
}

.seattle-img {
  height: 500px;
  /* margin-right: 50px;
  margin-left: 50px; */
  box-shadow: 5px 5px 2px 2px rgb(0 0 0 / 15%);
}

.blurb-container {
  font-family: 'Roboto', Courier, monospace;
  height: 500px;
  width: 550px;
  text-align: left;
}

.about-content-intro{
  font-size: 35px;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 40px;
}

.about-content {
  font-size: 20px;
}

.junk-cars {
  /* color: red; */
}

@media screen and (max-width: 700px) {
  .about-container {
    padding: 15px 0px 15px 0px;
  }
  .about-title {
    font-size: 25px;
    margin-bottom: 0px;
  }

  .about-content-container{
    width: 100vw;
    justify-content: center;
  }
  .blurb-container{
      padding: 0px 10px 0px 10px;
    }
  .about-content-intro {
    font-size: 20px;
  }
  .seattle-img { display: none };

  
}
