

.nav-container {
  height: 120px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 0px 50px 0px 50px; */
  /* background-color: rgba(1, 0, 78, 0.842); */
  background-color: transparent;
  color: gold;
  font-family: 'Ramabhadra', sans-serif;
  /* background: linear-gradient(
    right, rgba(0,0,0, 0.2), rgba(200,200,200, 0.01)
  ); */
}

/* rgba(32, 35, 37, 0.616) */
.logo-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  height: 100%;
  margin: 0;
  margin-left: 30px;
  padding: 0;
  background-color: rgba(1, 0, 78, 0.842);
  width: 450px;
  margin-top: 10px;
  border-radius: 10px;
} 

.logo, .and, .logo-llc {
  margin: 0;
}

.logo {
  font-size: 50px;
  font-family: 'Ramabhadra', sans-serif;
  letter-spacing: 3px;
  
}

.logo-llc {
  font-size: 20px;
  margin-top: -30px;
  /* color: white; */
}

.nav-items-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  /* width: 50%; */
  height: 100%;
  font-size: 20px;
  font-weight: 500;
  color: white;
  margin-right: 30px;
  text-shadow: 1px 2px gray;
}

.phone-icon {
  color: blue;
}

.service-area {
  margin-right: 30px;
}

.media-break-phone {
  display: none;
}


@media screen and (max-width: 700px) {
  .nav-container { 
    height: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .logo-div {
    margin: 0;
    height: 160px;
    width: 100vw;
    border-radius: 0;
  }

  .logo {
    font-size: 40px;
  }

  .nav-items-container {
    margin: 0;
    align-items: center;
  }


  .media-break-phone { display: block }
  .service-area { margin: 0 }
  .phone-icon { display: none }
  
  .contact { display: none }
}