.footer-container {
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: gold;
  color: rgba(1, 0, 78, 0.842);
  /* padding: 50px; */
  padding-top: 50px;
}

.footer-h3 {
  font-size: 25px;
  margin-bottom: 20px;
}

.footer-content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 30%;
  margin-bottom: 50px;
}

@media screen and (max-width: 700px) {
  .footer-h3 {
    font-size: 20px;;
    line-height: 25px;
    padding: 0 10px 0 10px;
    margin-bottom: 0px;
  }

  .footer-content-container {
    width: 80%;
    margin-bottom: 0px;
  }

  .footer-content-container img {
    width: 80px;
  }
}

/*   background-color: rgba(1, 0, 78, 0.842);
COLOR FOR BACKGROUND */
