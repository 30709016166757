.checklist-container {
  /* background-color: rgba(32, 35, 37, 0.897); */
  margin-top: 0px;
  padding-bottom: 50px;
  width: 100%;
  height: 50%;
}

ul {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 220px;
  margin-bottom: 50px;
}

li {
  list-style: none;
  font-size: 25px;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .checklist-container {
    margin-top: 15px;
  }

  ul {
    margin-left: 1rem;
  }

  li {
    font-size: 18px;
  }
}